import React from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import Pagination from '../../components/common/Pagination';
import AddBtn from '../../components/common/AddBtn';
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
} from '../../shared/enums/products_enum';
import DataTable from '../../components/common/DataTable';
import {
  useAllProducts,
  useGetDropdownOptions,
} from '../../shared/hooks/UseProduct';
import SidebarFilter from '../../components/common/SidebarFilter';
import { UPLOAD_URI, URI } from '../../domain/constant';
import { Link } from 'react-router-dom';
import TableViewBtn from '../../components/common/TableViewBtn';
import TableEditBtn from '../../components/common/TableEditBtn';
import TableDeleteBtn from '../../components/common/TableDeleteBtn';
const AllProducts = ({}) => {
  const [data, setPageNumber, deleteBtnClicked] = useAllProducts();
  const { products_loading, products, total_products, page, pages } = data;
  const [dropdownOptions, loadOptions] = useGetDropdownOptions();

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle='Dashboard'
          mainLinkUrl='/dashboard'
          activeLink={PAGE_TITLE}
        />

        <div className='container-fluid'>
          <div className='row'>
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
                loadOptions={loadOptions}
              />
            )}
            <div className='col-lg-10'>
              <div
                className='card'
                style={{ boxShadow: 'rgb(227 233 243) 0px 4px 22px' }}
              >
                <div className='card-body'>
                  <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} />
                  {total_products} records found
                  <div>
                    <Pagination
                      data={products}
                      page={page}
                      pages={pages}
                      count={total_products}
                      setPage={setPageNumber}
                      loading={products_loading}
                    />
                  </div>
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <thead>
                        <tr>
                          <th> # </th>
                          <th> Image </th>
                          <th> Product Name </th>
                          <th> Price </th>
                          <th> Collections </th>
                          <th> In Stock </th>
                          <th> Have Variations? </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {products &&
                          products.map((item, product_index) => {
                            return (
                              <tr>
                                <td> {product_index + 1 + (page - 1) * 16} </td>
                                <td>
                                  {item.media &&
                                    item.media.map((image, image_index) => {
                                      if (image_index < 2) {
                                        return (
                                          <img
                                            src={`${UPLOAD_URI}${image}`}
                                            style={{
                                              width: '75px',
                                              height: '75px',
                                              objectFit: 'cover',
                                              borderRadius: '75px',
                                            }}
                                          />
                                        );
                                      }
                                    })}
                                  {item.media &&
                                    item.media.length > 2 &&
                                    `+ ${item.media.length - 2} More`}
                                </td>
                                <td> {item.name} </td>
                                <td>
                                  {item.sale_price < item.regular_price && (
                                    <label
                                      style={{
                                        textDecoration: 'line-through',
                                        paddingRight: '10px',
                                      }}
                                    >
                                      {item.regular_price}{' '}
                                    </label>
                                  )}

                                  {item.sale_price}
                                </td>
                                <td>
                                  {item.collections &&
                                    item.collections.map((collection) => {
                                      return (
                                        <div>
                                          <span className='badge bg-warning'>
                                            {collection.name}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </td>
                                <td>
                                  <label
                                    className={
                                      item.in_stock
                                        ? 'badge bg-success'
                                        : 'badge bg-danger'
                                    }
                                  >
                                    {item.in_stock ? 'YES' : 'NO'}
                                  </label>
                                </td>
                                <td>
                                  <label
                                    className={
                                      item.is_variable_product
                                        ? 'badge bg-success'
                                        : 'badge bg-danger'
                                    }
                                  >
                                    {item.is_variable_product ? 'YES' : 'NO'}
                                  </label>
                                </td>
                                <td>
                                  <TableViewBtn id={item._id} item={LINK_URL} />
                                  <TableEditBtn id={item._id} item={LINK_URL} />
                                  <TableDeleteBtn
                                    id={item._id}
                                    deleteBtnClicked={deleteBtnClicked}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        {products && products.length === 0 && (
                          <tr>
                            <td colSpan={20}>No result found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>

                    {/* <DataTable
                      keys={view_all_table}
                      data={products}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={products_loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                    /> */}

                    <Pagination
                      data={products}
                      page={page}
                      pages={pages}
                      count={total_products}
                      setPage={setPageNumber}
                      loading={products_loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProducts;
