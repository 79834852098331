export const MENU = [
  {
    label: "Dashboard",
    link: "/dashboard",
    roles: ["SUPER ADMIN"],
  },
  {
    label: "Products",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Collections",
        link: "/collections",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Product Category",
        link: "/productcategorys",
        roles: ["SUPER ADMIN"],
      },

      {
        label: "Products",
        link: "/products",
        roles: ["SUPER ADMIN"],
      },

      {
        label: "Banners",
        link: "/banners",
        roles: ["SUPER ADMIN"],
      }
     
    ],
  },
  {
    label: "Orders",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Orders",
        link: "/orders",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Customers",
        link: "/customers",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Coupons",
        link: "/coupons",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Return Requests",
        link: "/return-requests",
        roles: ["SUPER ADMIN"],
      },
    ],
  },
  {
    label: "Blogs",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Categories",
        link: "/categorys",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Blogs",
        link: "/blogs",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Blog Comments",
        link: "/blog-comments",
        roles: ["SUPER ADMIN"],
      },
    ],
  },
  {
    label: "Contacts",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Contacts",
        link: "/contacts",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Newsletters",
        link: "/newsletters",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Testimonials",
        link: "/testimonials",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Reviews",
        link: "/reviews",
        roles: ["SUPER ADMIN"],
      },
    ],
  },
  {
    label: "Pages",
    link: "/pages",
    roles: ["SUPER ADMIN"],
  },
  // {
  //   label: "Vendors",
  //   link: "/vendors",
  //   roles: ["SUPER ADMIN"],
  // },
  {
    label: "Menu",
    link: "/menus",
    roles: ["SUPER ADMIN"],
  },
  {
    label: "Settings",
    // link: "/homepages",
    link: "#",
    roles: ["SUPER ADMIN"],
    menu: [
      {
        label: "Homepage",
        link: "/homepages",
        roles: ["SUPER ADMIN"],
      },
      {
        label: "Template",
        link: "/templates",
        roles: ["SUPER ADMIN"],
      },
    ],
  },
];
