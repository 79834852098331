import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    addBlogcomment,
    getBlogcomments,
    getBlogcomment,
    editBlogcomment,
    deleteBlogcomment,
    getAllBlogcomments,
} from "../../store/actions/blogcomment_action";
import _debounce from "lodash/debounce";
import { useSelectAllCategory } from "./UseCategory";

// Get All Data
export const useAllBlogcomments = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.blogcomment);
    const [pageNumber, setPageNumber] = useState(1);
    const [deleteEntry, setDeleteEntry] = useState(null);

    useEffect(() => {
        if (deleteEntry) {
            dispatch(deleteBlogcomment(deleteEntry));
        }
        allQuery();
    }, [deleteEntry, pageNumber, window.location.search]);
    const allQuery = useCallback(
        _debounce(() => {
            dispatch(getBlogcomments({}));
        }, 1000),
        []
    );

    const deleteBtnClicked = async (id) => {
        setDeleteEntry(id);
    };

    return [data, setPageNumber, deleteBtnClicked];
};

// Get Single Data
export const useSingleBlogcomment = (id) => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.blogcomment);
    useEffect(() => {
        dispatch(getBlogcomment(id));
    }, [id]);
    return [data];
};
// Add Data
export const useCreateBlogcomment = () => {
    const dispatch = useDispatch();
    const data = useSelector((state) => state.blogcomment);
    const addData = async (data) => {
        await dispatch(addBlogcomment(data));
    };
    return [data, addData];
};
export const useUpdateBlogcomment = () => {
    const dispatch = useDispatch();
    // const data = useSelector((state) => state.blogcomment);
    const updateData = async (id, data) => {
        await dispatch(editBlogcomment(id, data));
    };
    return [updateData];
};

export const useSelectAllBlogcomment = () => {
    const dispatch = useDispatch();
    const [term, setTerm] = useState("");
    const [value, setValue] = useState("");
    const data = useSelector((state) => state.blogcomment);
    useEffect(() => {
        dispatch(getAllBlogcomments({ term, value }));
    }, [term, value]);
    return [data, setTerm, setValue];
};

export const useGetDropdownOptions = () => {
    const [category, setBCategorySearchField, setCategorySearchValue] =
        useSelectAllCategory();

    const [dropdownOptions, setDropdownOptions] = useState({});
    useEffect(() => {
        if (category && category.all_categorys) {
            const newData = category.all_categorys.map((item) => {
                return { label: item.name, value: item._id };
            });
            setDropdownOptions({ ...dropdownOptions, category: newData });
        }
    }, [category]);
    const loadOptions = async (inputValue, callback, field) => {
        // if (field == "parent_category") {
        //   await setCategorySearchField("name");
        //   await setCategorySearchValue(inputValue);
        //   callback(dropdownOptions.parent_category);
        // }
    };

    return [dropdownOptions, loadOptions];
};
