import React, { useEffect } from 'react';
import BreadCrumb from '../../components/template/BreadCrumb';
import Header from '../../components/template/Header';
import Pagination from '../../components/common/Pagination';
import AddBtn from '../../components/common/AddBtn';
import {
  view_all_table,
  PAGE_TITLE,
  PAGE_SINGLE_TITLE,
  LINK_URL,
  SIDEBAR_OPTIONS,
  inputFields,
  exportFields,
} from '../../shared/enums/orders_enum';
import DataTable from '../../components/common/DataTable';
import {
  useAllOrders,
  useGetDropdownOptions,
} from '../../shared/hooks/UseOrder';
import SidebarFilter from '../../components/common/SidebarFilter';
import { Link } from 'react-router-dom';
import ExportComponent from '../../components/common/ExportComponent';
import { UseDataForOrder, UseFilter } from '../../shared/hooks/UseExcel';
const AllOrders = ({}) => {
  const [data, setPageNumber, deleteBtnClicked] = useAllOrders();
  const { orders_loading, orders, total_orders, page, pages } = data;
  const [dropdownOptions, loadOptions] = useGetDropdownOptions();
  const [exportXLSXData] = UseFilter();

  const [convertToReadable, exportData] = UseDataForOrder();

  useEffect(() => {
    if (orders) {
      convertToReadable(orders, exportFields);
    }
  }, [orders]);

  const handleOnExport = () => {
    exportXLSXData(exportData, 'Orders', 'orders');
  };

  return (
    <div className='pace-done'>
      <div>
        <Header />
        <BreadCrumb
          title={PAGE_TITLE}
          mainLinkTitle='Dashboard'
          mainLinkUrl='/dashboard'
          activeLink={PAGE_TITLE}
        />
        <ExportComponent handleOnExport={handleOnExport} />
        <div className='container-fluid'>
          <div className='row'>
            {SIDEBAR_OPTIONS && (
              <SidebarFilter
                SIDEBAR_OPTIONS={SIDEBAR_OPTIONS}
                dropdown_options={dropdownOptions}
                loadOptions={loadOptions}
              />
            )}
            <div className='col-lg-10'>
              <div
                className='card'
                style={{ boxShadow: 'rgb(227 233 243) 0px 4px 22px' }}
              >
                <div className='card-body'>
                  {/* <AddBtn item={LINK_URL} title={PAGE_SINGLE_TITLE} /> */}
                  {total_orders} records found
                  <div>
                    <div className='d-flex'>
                      <div className='order-status-btn mtb-3 '>
                        <Link to={'/orders?exact[is_paid]=false'}>
                          <button className='btn btn-danger'>
                            Failed Orders
                          </button>
                        </Link>
                      </div>
                      <div className='order-status-btn mtb-3 ml-5 '>
                        <Link to={'/orders?exact[is_paid]=true'}>
                          <button className='btn btn-success'>
                            Successful Orders
                          </button>
                        </Link>
                      </div>
                    </div>

                    <DataTable
                      keys={view_all_table}
                      data={orders}
                      field={LINK_URL}
                      page={page}
                      deleteBtnClicked={deleteBtnClicked}
                      loading={orders_loading}
                      inputFields={inputFields}
                      PAGE_TITLE={PAGE_TITLE}
                      PAGE_SINGLE_TITLE={PAGE_SINGLE_TITLE}
                      hideQVBtn={true}
                      hideEditBtn={true}
                    />

                    <Pagination
                      data={orders}
                      page={page}
                      pages={pages}
                      count={total_orders}
                      setPage={setPageNumber}
                      loading={orders_loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllOrders;
