import React, { useState } from 'react';

function TableDeleteBtn({ id, deleteBtnClicked }) {
  const [setToDelete, setSetToDelete] = useState(false);
  return (
    <>
      <button onClick={() => setSetToDelete(true)} className='btn text-danger'>
        <i className='fa fa-trash'></i>
      </button>
      {setToDelete && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            background: 'rgba(0,0,0,0.4)',
            zIndex: 999,
          }}
        >
          <div
            style={{
              width: '25%',
              margin: '15% auto',
              background: '#fff',
              padding: '20px 20px',
              borderRadius: '10px',
            }}
          >
            <h3> Are you sure? </h3>
            <div> Do you really want to delete ? </div>
            <div>
              <div
                className='d-flex'
                style={{ gap: '10px', paddingTop: '20px' }}
              >
                <button
                  className='btn btn-secondary'
                  onClick={() => setSetToDelete(false)}
                >
                  {' '}
                  Cancel{' '}
                </button>
                <button
                  className='btn btn-danger'
                  onClick={() => deleteBtnClicked(id)}
                >
                  {' '}
                  Delete{' '}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TableDeleteBtn;
