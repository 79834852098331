import React, { useEffect } from 'react';
import { CheckBox, GalleryUpload, TextInput, SelectBox } from '../Form/Form';
import { FieldArray } from 'formik';
import 'lodash.combinations';
import _, { some } from 'lodash';

function VariationTable({ formik, item }) {
  const combineObjects = ([head, ...[headTail, ...tailTail]]) => {
    if (!headTail) return head;

    const combined = headTail.reduce((acc, x) => {
      return acc.concat(head.map((h) => ({ ...h, ...x })));
    }, []);

    return combineObjects([combined, ...tailTail]);
  };

  useEffect(() => {
    if (formik.values.variation_attrs) {
      let freshArrayToIterate = formik.values.variation_attrs.map((item) => {
        let newObjectValues = [];
        item.options.map((sub_item) => {
          let someObject = {};
          someObject[item.label] = sub_item.value;
          newObjectValues.push(someObject);
        });
        return newObjectValues;
      });
      let newArrayToCheck = [];
      freshArrayToIterate.map((item) => {
        newArrayToCheck.push(item);
      });
      console.log('Somnehting Check', newArrayToCheck);
      const freshArrayToObject = combineObjects(newArrayToCheck);

      let newArrayToAdd =
        freshArrayToObject &&
        freshArrayToObject.map((item, options_index) => {
          console.log('item', item);
          const keys = Object.keys(item);

          let options = [];
          keys.map((key) => {
            let someKey = {
              label: key,
              value: item[key],
            };

            options.push(someKey);
          });

          item.price =
            formik.values['variations'] &&
            formik.values['variations'][options_index] &&
            formik.values['variations'][options_index].price
              ? formik.values['variations'][options_index].price
              : formik.values.regular_price
              ? formik.values.regular_price
              : '';
          item.options = options;

          item.in_stock =
            formik.values['variations'] &&
            formik.values['variations'][options_index] &&
            formik.values['variations'][options_index].in_stock
              ? formik.values['variations'][options_index].in_stock
              : true;
          item.stock =
            formik.values['variations'] &&
            formik.values['variations'][options_index] &&
            formik.values['variations'][options_index].stock
              ? formik.values['variations'][options_index].stock
              : null;

          // item.media =
          //   formik.values['variations'] &&
          //   formik.values['variations'][options_index] &&
          //   formik.values['variations'][options_index].media
          //     ? formik.values['variations'][options_index].media
          //     : [];

          if (
            formik.values['variations'] &&
            formik.values['variations'][options_index] &&
            formik.values['variations'][options_index].media
          ) {
            // Check if media available
            item.media = formik.values['variations'][options_index].media;
          } else {
            // Get medias from above parts

            // Check if there is no media available
            const variation_attrs = formik.values.variation_attrs;
            let media = [];
            console.log('options', options);
            const mappedData = options.map((item) => {
              // const keys = Object.keys(item);
              // console.log('keys New', keys, item, variation_attrs);
              const filteredData = variation_attrs.filter((variation) => {
                if (item.label === variation.label && variation.is_photo) {
                  return item;
                }
              });
              if (filteredData && filteredData.length > 0) {
                // Get Options
                const newFilterData = filteredData[0].options?.filter(
                  (option) => option.value === item.value
                );
                if (newFilterData && newFilterData.length > 0) {
                  media = newFilterData[0].media;
                } else {
                  media = formik.values.media;
                }
              }
            });
            item.media = media;

            // Get the variation_attrs object from label and value
            // Get Media and update it
          }

          item.sku = formik.values.sku
            ? `${formik.values.sku}-${options_index + 1}`
            : '';
          return item;
        });

      formik.setFieldValue('variations', newArrayToAdd);
    }
  }, [formik.values.variation_attrs]);

  return (
    <div>
      <div> Variants </div>

      <FieldArray name={item}>
        {(fieldArrayProps) => {
          const { push, remove, form } = fieldArrayProps;
          const { values } = form;
          const arrayValues = values[item] ? values[item] : [];
          return (
            <div>
              <div
                style={{
                  border: '1px solid #f1f1f1',
                  margin: '10px 10px',
                  padding: '10px',
                }}
              >
                {arrayValues.map((single, fieldIndex) => (
                  <div key={fieldIndex} className='row'>
                    <div className='col-md-12'>
                      <div
                        className='d-flex align-items-center '
                        style={{
                          gap: '20px',
                          borderTop: '1px solid #f1f1f1',
                          padding: '10px 10px',
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <div>
                            {' '}
                            {formik.values[item] &&
                              formik.values[item][fieldIndex] &&
                              formik.values[item][fieldIndex].options &&
                              formik.values[item][fieldIndex].options.map(
                                (option, option_index) => {
                                  return (
                                    <span>
                                      {' '}
                                      {option.value}{' '}
                                      {option_index !==
                                        formik.values[item][fieldIndex].options
                                          .length -
                                          1 && '/'}
                                    </span>
                                  );
                                }
                              )}{' '}
                          </div>
                        </div>
                        <div style={{ flex: 1 }}>
                          <TextInput
                            type='text'
                            name={`${item}[${fieldIndex}].price`}
                            placeHolder='Price'
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <TextInput
                            type='text'
                            name={`${item}[${fieldIndex}].sku`}
                            placeHolder='SKU'
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          {/* {console.log("MEDIA", formik.values[item])} */}
                          <GalleryUpload
                            title={'Media'}
                            item={`${item}[${fieldIndex}].media`}
                            formik={formik}
                            value={formik.values[item][fieldIndex].media}
                          />
                        </div>

                        {
                          //   <div style={{ flex: 1 }}>
                          //   <CheckBox
                          //     label={"In Stock"}
                          //     name={`${item}[${fieldIndex}].in_stock`}
                          //     // defaultChecked={formik.values[item][fieldIndex].in_stock}
                          //     defaultChecked={formik.values["variations"][fieldIndex].in_stock}
                          //   // placeHolder="SKU"
                          //   // checked = {}
                          //   >
                          //     In Stock
                          //   </CheckBox>
                          // </div>
                        }

                        <div style={{ flex: 1 }}>
                          <SelectBox
                            label='In Stock'
                            name={`${item}[${fieldIndex}].in_stock`}
                            placeholder='Enter In Stock'
                          >
                            <option value=''> Select In Stock </option>
                            <option value={true}> YES </option>
                            <option value={false}> NO</option>
                          </SelectBox>
                        </div>
                        <div style={{ flex: 1 }}>
                          <TextInput
                            type='text'
                            name={`${item}[${fieldIndex}].stock`}
                            placeHolder='Stock'
                          />
                        </div>

                        <div style={{ flex: 1 }}>
                          {fieldIndex > 0 ? (
                            <a
                              className='btn btn-sm btn-danger'
                              onClick={() => remove(fieldIndex)}
                            >
                              -
                            </a>
                          ) : (
                            <a
                              className='btn btn-sm btn-danger'
                              onClick={() => remove(fieldIndex)}
                            >
                              -
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
}

export default VariationTable;
