import {
    GET_BLOGCOMMENTS_STATED,
    GET_BLOGCOMMENTS,
    GET_BLOGCOMMENTS_ENDED,
    ADD_BLOGCOMMENT_STATED,
    ADD_BLOGCOMMENT,
    ADD_BLOGCOMMENT_ENDED,
    EDIT_BLOGCOMMENT_STATED,
    EDIT_BLOGCOMMENT,
    EDIT_BLOGCOMMENT_ENDED,
    GET_BLOGCOMMENT_STATED,
    GET_BLOGCOMMENT,
    GET_BLOGCOMMENT_ENDED,
    GET_ALL_BLOGCOMMENTS_STATED,
    GET_ALL_BLOGCOMMENTS,
    GET_ALL_BLOGCOMMENTS_ENDED
} from "../types/blogcomment_type";

const initialState = {
    blogcomments_loading: true,
    blogcomments: null,
    page: null,
    pages: null,
    total_blogcomments: 0,

    blogcomment: null,
    blogcomment_loading: null,

    loading: true,

    blogcomment_message: null,
    all_blogcomments: null,
    all_blogcomments_loading: null,
    add_blogcomment_loading: true,
    edit_blogcomment_loading: true
};

export const blogcomment_reducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_BLOGCOMMENTS_STATED:
            return {
                ...state,
                blogcomments: null,
                pages: null,
                page: null,
                total_blogcomments: 0,
                blogcomments_loading: true
            };
        case GET_BLOGCOMMENTS:
            return {
                ...state,
                blogcomments: payload.blogcomments,
                pages: payload.pages,
                page: payload.page,
                total_blogcomments: payload.count
            };
        case GET_BLOGCOMMENTS_ENDED:
            return {
                ...state,
                blogcomments_loading: false
            };
        case GET_ALL_BLOGCOMMENTS_STATED:
            return {
                ...state,
                all_blogcomments_loading: true,
                all_blogcomments: null
            };
        case GET_ALL_BLOGCOMMENTS:
            return {
                ...state,
                all_blogcomments: payload
            };
        case GET_ALL_BLOGCOMMENTS_ENDED:
            return {
                ...state,
                all_blogcomments_loading: false
            };

        case ADD_BLOGCOMMENT_STATED:
            return {
                ...state,
                blogcomment_message: null,
                add_blogcomment_loading: true
            };
        case ADD_BLOGCOMMENT:
            return {
                ...state,
                blogcomment_message: payload
            };
        case ADD_BLOGCOMMENT_ENDED:
            return {
                ...state,
                add_blogcomment_loading: false
            };
        case GET_BLOGCOMMENT_STATED:
            return {
                ...state,
                blogcomment: null,
                blogcomment_loading: true
            };
        case GET_BLOGCOMMENT:
            return {
                ...state,
                blogcomment: payload
            };
        case GET_BLOGCOMMENT_ENDED:
            return {
                ...state,
                blogcomment_loading: false
            };
        case EDIT_BLOGCOMMENT_STATED:
            return {
                ...state,
                blogcomment_message: null,
                edit_blogcomment_loading: true
            };
        case EDIT_BLOGCOMMENT:
            return {
                ...state,
                blogcomment_message: payload
            };
        case EDIT_BLOGCOMMENT_ENDED:
            return {
                ...state,
                edit_blogcomment_loading: false
            };

        default:
            return state;
    }
};
